@media(min-width: 360px) {
    .divHomeResetPass {
        border-radius: 2%;
        display: flex;
        justify-content: space-between;
        padding-top: 7%;
        margin: 10%;
    }

    .containerResetPass {
        display: flex;
        flex-direction: column;
        margin-top: 10%;
    }

    .containerResetPass div {
        margin: 5%;
    }

    .copyRightResetPass {
        position: absolute;
        left: -29%;
        top: 83%;
    }

    h4 {
        margin-top: 5%;
        text-align: center;
    }

    .containerResetPass button {
        margin: 0 auto;
        width: 95%;
    }

    .SyncLockIconResetPassword {
        left: 81%;
        position: absolute;
        top: 42%;
    }

    .SyncLockIconResetPassword2 {
        left: 81%;
        position: absolute;
        top: 61%;
    }

    .errorEmailOlvidoPassword1 {
        position: absolute;
        left: 10%;
        color: red;
        font-size: small;
        top: 47%;
    }

    .errorEmailOlvidoPassword2 {
        position: absolute;
        left: 10%;
        color: red;
        font-size: small;
        top: 66%;
    }
}

@media(min-width: 576px) {
    .containerResetPass {
        margin: 0 auto;
        width: 95%;
    }

    .containerResetPass div {
        margin: 3%;
    }
    .copyRightResetPass {      
        left: -16%;        
    }
    .errorEmailOlvidoPassword1 {
        left: 9%;
        top: 48%;
    }

    .errorEmailOlvidoPassword2 {
        left: 9%;
        top: 67%;
    }

    h4 {
        margin-top: -5%;

    }
    .SyncLockIconResetPassword {
        left: 85%;
        top: 44%;
    }

    .SyncLockIconResetPassword2 {        
        left: 85%;
        top: 62%;
    }
}
@media(min-width: 768px) {
    .containerResetPass div {
        margin: 1%;
    }
    .copyRightResetPass {      
        left: -10%;        
        top: 85%;
    }
    .errorEmailOlvidoPassword1 {
        left: 6%;
        top: 53%;
    }

    .errorEmailOlvidoPassword2 {
        left: 6%;
        top: 69%;
    }
    .SyncLockIconResetPassword {
        left: 89%;        
        top: 49%;
    }

    .SyncLockIconResetPassword2 {
        left: 89%;        
        top: 65%;
    }
}
@media(min-width: 992px) {
    .copyRightResetPass {      
        left: -8%;        
        top: 93%;
    }
    .errorEmailOlvidoPassword1 {
        left: 6%;
        top: 61%;
    }

    .errorEmailOlvidoPassword2 {
        left: 6%;
        top: 78%;
    }
    .SyncLockIconResetPassword {
        left: 89%;        
        top: 49%;
    }

    .SyncLockIconResetPassword2 {
        left: 89%;        
        top: 65%;
    }
}