.formulario{
    border: 2px solid blue;
    display: flex;  
    flex-wrap: wrap;       
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    width: 20%;
}
label{
    font-weight: bold;
    display: flex;
    margin-bottom: 5px;
}
input[type='number']{
    display: block;
    width: 200px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: "fff";
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* margin-bottom: 40px; */
}
