@media(min-width: 360px) {
    .containerPrincipalOlvidoPass {
        border: solid 2px gainsboro;
        border-radius: 2%;
        width: 100%;
    }

    .containerPrincipalOlvidoPass div {
        margin-top: 18%;
        padding: 5%;

    }

    .containerPrincipalOlvidoPass button {
        width: 100%;
    }

    .containerPrincipalOlvidoPass .emailOvidoPass {
        margin-bottom: -25%;
    }

    .copyRightOlvidoPass {
        left: -28%;
        position: absolute;
        top: 93%;
    }

    .divHomeOlvidoPass {
        border: solid 1px gainsboro;
        border-radius: 2%;
        display: flex;
        justify-content: space-evenly;
        padding-top: 2%;
        width: 100%;

    }

    .divHomeOlvidoPass .keyIcon {
        margin-left: 70%;
    }

    .divHomeOlvidoPass p {
        font-size: 15px;
    }

    .formOlvidoPassword {
        border-radius: 9px 9px 9px 9px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        margin: 20px;
        height: 558px;
    }

    /* 
form .textoOlvidoContraseña{
    margin-left: 40%;
    position: absolute;
    top: 20%;
} */
    .errorEmailOlvidoPassword {
        color: red;
        font-size: 12px;
        left: 11%;
        position: absolute;
        top: 39%;
    }

    .MailOutlineIconForgotPassword {
        left: 82%;
        position: fixed;
        top: 34%;
    }
}

@media(min-width: 576px) {
    .copyRightOlvidoPass {
        left: -15%;
    }

    .divHomeOlvidoPass {
        justify-content: space-around;
    }

    .errorEmailOlvidoPassword {
        left: 9%;
        top: 48%;
    }

    .MailOutlineIconForgotPassword {
        left: 85%;
        position: absolute;
        top: 43%;
    }
    
}
@media(min-width: 768px) {
    .errorEmailOlvidoPassword {
        left: 8%;
        top: 55%;
    }
    
    .MailOutlineIconForgotPassword {
        left: 88%;        
        top: 50%;
    }
    .copyRightOlvidoPass {
        left: -9%;
     
    }
}
@media(min-width: 992px) {
    .errorEmailOlvidoPassword {
        left: 7%;
        top: 63%;
    }
    
    .MailOutlineIconForgotPassword {
        left: 89%;        
        top: 58%;
    }
}